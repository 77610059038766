var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Yup from "yup";
import { generateFieldValidation } from "../sections/@dashboard/statClientResponse/form/utils/generateFieldValidation";
import { emailRegex, phoneRegex } from "../utils/regex";
export var statClientResponseValidation = function (kpis) {
    var validationsFields = {};
    if (kpis) {
        kpis.forEach(function (field) {
            var schema = generateFieldValidation(field);
            validationsFields[field === null || field === void 0 ? void 0 : field.name] = schema;
        });
    }
    return Yup.object().shape(__assign(__assign({}, validationsFields), { clientName: Yup.string().min(2).max(60).required(), clientContact: Yup.string()
            .required('Client contact is required')
            .test('is-email-or-phone', 'Client contact must be a valid email or a phone number containing exactly 8 digits', function (value) { return value !== undefined && (emailRegex.test(value) || phoneRegex.test(value)); })
            .min(2, 'Client contact must be at least 2 characters long')
            .max(60, 'Client contact must be at most 60 characters long') }));
};
